.inner-container{
    gap: 1.5rem;
    background: orange;
    padding: 2rem;
    border-radius: 2px;
    border: 6px solid rgb(248, 172, 32);
    text-align: center;
}
.inner-container .primaryText{
    color:white;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgba(255, 255, 255, 0.857);
    font-weight: 600;
}
.inner-container .button{
    border: 2px solid white;
    box-shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
    border-radius: 10px;
}