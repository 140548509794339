.App{
    position: relative;
    overflow-x: clip;
    background-color: black;
}
.App>:nth-child(1){
    background: black;
    position:  relative;
}
.white-gradient{
    position: absolute;
    width: 20rem;
    height: 20rem;
    background: rgba(255,255,255,0.522);
    filter: blur(200px);
    border-radius: 100px;
}