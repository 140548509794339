.zm-container {
    overflow: hidden;
    position: relative;
  }
/* YourComponent.css */

.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    max-width: 800px;
    margin: auto;
  }
  
  .gallery img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .gallery img:hover {
    scale: 1.2;
    cursor: pointer;
    background:  linear-gradient(
        180deg,
        rgba(255,255,255,0) 0%,
        rgba(255,165,0,0.46) 217.96%
    );
    box-shadow: 0px 72px 49px -51px rgba(255, 165, 0, 0.21);
}
  
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    overflow: auto;
  }
  
  .modal img {
    width: 80%;
    max-width: 800px;
    margin: auto;
    display: block;
    margin-top: 10%;
  }
  
  .modal .close {
    color:  orange;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }
  