.hero-wrapper{
    color:white;
    position:relative;
    padding-bottom:2rem;
   
}
.hero-container{
    justify-content: space-between;
    align-items: flex-end;
}
/*video-container*/
.video-container{
     width: 40rem;
     height: 35rem;
     overflow: visible;
     opacity: 100%;
}
.video-container>video{
    /*object-fit*/
    height: 100%;
    width: 100;
}
.hero-left{
    gap:3rem;
}
.hero-title{
    position: relative;
    z-index: 1;
}
.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}
.circle{
    height: 4rem;
    width: 4rem;
    background: linear-gradient(97.05deg, rgb(230, 197, 11) 3.76%, orange 100%);
    right: 28%;
    top: -10%;
    z-index: -1;
    border-radius: 999px;
    position: absolute;
}
.mail{
    background-color: white;
    border-radius:5px ;
    border: 3px solid rgba(120,120,120,0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    
}
.mail>input{
    border: none;
    outline:none;
}
.stats{
    width: 50%;
    justify-content: space-between;

}
.stat>:nth-child(1){
    font-size: 2rem;
}
.stat>:nth-child(1)>:last-child{
    
    color:orange
}
@media (max-width: 640px){
    .hero-container{
        margin-top: 2rem;
    }
    .hero-title{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .video-container{
        width: 95%;
        height: 25rem;
    }
    .stats{
        justify-content: center;
        gap:1.5rem;
    }
    .hero-right{
        width: 100%;
    }
}
.c-container{
    justify-content: space-around;
    gap: 1rem;
}
.c-container>img{
    width:9rem;
}
.c-container>img:hover{
    transform: scale(1.1);
}
.c-container>h1{
    color: white;
    font-style: italic;
}
