.a-container{
    overflow: hidden;
    position: relative;
}
.a-head{
    color:white;
     margin-bottom: 2rem;

}
.a-card{
    gap:0.6rem;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: auto;
    transition: all 200ms ease-in;

}
.a-card>img{
    width: 120%;
    max-width: 45rem;
}
.a-card:hover{
    scale: 1.025;
    cursor: pointer;
    background:  linear-gradient(
        180deg,
        rgba(255,255,255,0) 0%,
        rgba(255,165,0,0.46) 217.96%
    );
    box-shadow: 0px 72px 49px -51px rgba(255, 165, 0, 0.21);
}
.a-button{
    position: absolute;
    gap: 1rem;
    top: -4rem;
    right: 0;

}
.a-button button{
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color:orange;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}
.content{
    color:white;
   font-size: 18px;
   line-height: 1.6;
   text-align: justify;
   margin-bottom: 20px;
   text-shadow: orange;
}
.a{
    color:white;
}