.f-container{
    justify-content: space-between;
    border-top: .5px solid rgba(0,0,0,0.14);
}
.f-left{
    gap:1rem; 
}
.f-menu{
    gap: 1.5rem;
    margin-top: 1.5em;
    font-weight: 500;
    color:white;
}
.f-menu :hover{
    cursor: pointer;
    color:orange;
}