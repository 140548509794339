
.h-wrapper{
 
   color:white; 
}
.h-wrapper h1 {
    font-size:25px;
    font-weight: bold;
    font-family: 'kanit', 'sans-serif';
    font-style: italic;
}
.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.h-menu{
    gap:2rem;
}
.h-menu :hover{
    cursor: pointer;
    color:orange;
}
.menu-icon{
    display: none;
}

@media (max-width:768px) {
    .menu-icon{
        display: block;
    }
    .h-menu{
        color: white;
        position: absolute;
        top:3rem;
        right:4rem;
        background-color: black;
        flex-direction: column;
        font-weight: 500;
        gap:2rem;
        padding:3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        transition: all 300ms ease-in;
    }
    
}


